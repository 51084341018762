body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  display: flex;
  align-items: center;
  margin: 16px;
  padding: 16px;
  flex-direction: column;
  background-color:#f9f9f9;
}

.form{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  width: fit-content;
  height: fit-content;
}

.form-container{
  width: 700px;
  height: 300px;
  padding: 36px;
  height: fit-content;
}

.table-container{
  display: flex;
  justify-content: center;
  width: 90%;
}

.styled-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    border-top-left-radius: 20px ;
    border-top-right-radius: 20px ;
}

.styled-table th,
.styled-table td {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    height: 36px;
    
}

.styled-table th {
    background-color:#0078FF;
    width: 150px;
    height: 40px;
    font-weight: bold;
    color: white;
}
/* Styles for all scrollbars */
*::-webkit-scrollbar {
  width: 8px;  
  height: 8px; 
}

*::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

*::-webkit-scrollbar-thumb {
  background-color: #012355; 
  border-radius: 20px;
  border: 2px solid transparent; /* creates padding around scroll thumb */
}